import React, { useEffect, useState } from "react";
import LGCrud from "../../LGCrud";
import {
  addProviderActivity,
  deleteProviderActivity,
  getProviderActivityList,
  updateProviderActivity,
} from "../../../api/providers";
import LGSelectCellRenderer from "../../LGCrud/LGSelectCellRenderer";
import LGSelectCellEditor from "../../LGCrud/LGSelectCellEditor";
import { getExpenseAccountList } from "../../../api/accounting";
import { useSnack } from "../../../services/SnakbarProvider";
import { handleError } from "../../../services/errorHandler";
import { useCompany } from "../../../contexts/CompanyContext";

export default function ActivityTable({ props }) {
  const { id } = props;
  const [expenseAccounts, setExpenseAccounts] = useState([]);
  const { openSnack } = useSnack();
  const { selectedCompany } = useCompany();

  const fetchExpenseAccounts = async () => {
    try {
      const res = await getExpenseAccountList({ companyId: selectedCompany.id, pageSize: 1000 });
      setExpenseAccounts(res.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const handlePost = async (data) => {
    const payload = { ...data };
    payload.expenseAccountId = payload.expenseAccount.id;
    payload.providerId = id;
    try {
      const res = await addProviderActivity(payload);
      openSnack("Actividad económica agregada correctamente", "success");
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const handleUpdate = async (id, data) => {
    const payload = { ...data };
    const res = await updateProviderActivity(id, payload);
  };

  const handleDelete = async (id) => {
    const res = await deleteProviderActivity(id);
  };

  useEffect(() => {
    if (id) {
      fetchExpenseAccounts();
    }
  }, [id]);

  return (
    <LGCrud
      parentId={{ name: "providerId", value: id }}
      columns={[
        {
          field: "expenseAccount",
          headerName: "Actividad económica",
          flex: 0.5,
          editable: true,
          renderCell: (params) => (
            <LGSelectCellRenderer
              value={params.value}
              labelTemplate={(v) => `${v.name} | ${v.contpaqiCode}`}
            />
          ),
          renderEditCell: (params) => (
            <LGSelectCellEditor
              {...params}
              dropdownData={expenseAccounts}
              labelTemplate={(v) => `${v.name} | ${v.contpaqiCode}`}
            />
          ),
        },
      ]}
      actionHandlers={{
        create: handlePost,
        update: handleUpdate,
        delete: handleDelete,
        fetchData: getProviderActivityList,
      }}
    />
  );
}
