import React, { useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import CrudProducts from "../../organisms/Products/ProductCrud";
import { useCompany } from "../../contexts/CompanyContext";
import { Box, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import { LGTabPanel } from "../../molecules/LGTabPanel";
import CrudCatalogue from "../../organisms/Catalogues/CrudCatalogue";
import CrudProductCategories from "../../organisms/Catalogues/CrudProductCategories.tsx";

export default function ProductScreen() {
  const { selectedCompany } = useCompany();
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (!selectedCompany) {
    return (
      <DashboardLayout>
        <Box p={4}>
          <Typography>Seleccione una compañía primero</Typography>
        </Box>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <Box
        sx={{
          backgroundColor: "white", 
          borderBottom: 1,
          borderColor: "divider",
          position: "fixed",
          zIndex: 99,
          width: "100%",
        }}
      >
        <Tabs value={activeTab} onChange={handleChange}>
          <Tab label={`Productos ${selectedCompany.name}`} />
          <Tab label="Catálogos" />
        </Tabs>
      </Box>

      <LGTabPanel value={activeTab} index={0}>
        <CrudProducts companyId={selectedCompany.id} />
      </LGTabPanel>

      <LGTabPanel value={activeTab} index={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Stack direction={"column"} spacing={2} mt={5}>
              <Typography variant="h4">Tipos de Productos</Typography>
              <CrudCatalogue catalogueName="PRODUCT_TYPE" />
              <Typography variant="h4">Acabados</Typography>
              <CrudCatalogue catalogueName="PRODUCT_FINISH" />
              <Typography variant="h4">Colores</Typography>
              <CrudCatalogue catalogueName="PRODUCT_COLOR" />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction={"column"} spacing={2} mt={5}>
              <Typography variant="h4">Categorías de Productos</Typography>
              <CrudProductCategories />
            </Stack>
          </Grid>
        </Grid>
      </LGTabPanel>

    </DashboardLayout>
  );
}
