import React, { createContext, useContext, useState, useEffect } from 'react';
import { getCompanyList } from '../api/enterprise';
import { getUserCompany, getUserRoles } from '../services/auth';

const CompanyContext = createContext();

export const CompanyProvider = ({ children }) => {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchCompanies = async () => {
    setLoading(true);
    setError(null);
    try {
      const [companyListRes, userRoles, userDefaultCompany] = await Promise.all([
        getCompanyList(),
        getUserRoles(),
        getUserCompany()
      ]);

      const userCompanyIds = userRoles.companyRoles.map((role) => role.CompanyId);
      const filteredCompanies = companyListRes.data.filter((company) => userCompanyIds.includes(company.id));

      setCompanies(filteredCompanies);

      let initialCompany = filteredCompanies.find((c) => c.id === parseInt(localStorage.getItem('selectedCompanyId')))
        || filteredCompanies.find((c) => c.id === userDefaultCompany)
        || filteredCompanies[0];

      if (initialCompany) {
        localStorage.setItem('selectedCompanyId', initialCompany.id);
        setSelectedCompany(initialCompany);
      }
    } catch (error) {
      console.error('Error fetching companies:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  return (
    <CompanyContext.Provider value={{ selectedCompany, setSelectedCompany, companies, loading, error, refreshCompanies: fetchCompanies }}>
      {children}
    </CompanyContext.Provider>
  );
};


export const useCompany = () => useContext(CompanyContext);
