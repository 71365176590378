import React from "react";
import { Grid } from "@mui/material";
import { FieldArray, Field, useFormikContext, FastField } from "formik";
import { TextField } from "@mui/material";
import { Icon, Button, IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import DropdownSelector from "../../atoms/Common/DropdownSelector";
import { getCatalogue } from "../../api/common/catalogues";

export default function ProductFormProperties() {
  const { values } = useFormikContext();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FieldArray name="props">
          {({ push, remove }) => (
            <Grid container item xs={12} spacing={2}>
              {values.props.map((prop, index) => (
                <>
                  <Grid item xs={6}>
                    <FastField
                      fullWidth
                      name={`props.${index}.key`}
                      type="text"
                      label="Clave"
                      as={DropdownSelector}
                      fetchFunction={getCatalogue}
                      fetchParams={{ catalogueName: "PRODUCT_PROP" }}
                      keyField="code"
                    />
                  </Grid>
                  <Grid item xs={5}>
                    {values.props[index].key === "C" ? (
                      <Field
                        fullWidth
                        name={`props.${index}.value`}
                        type="text"
                        label="Valor"
                        as={DropdownSelector}
                        fetchFunction={getCatalogue}
                        fetchParams={{ catalogueName: "PRODUCT_COLOR" }}
                        keyField="id"
                      />
                    ) : values.props[index].key === "F" ? (
                      <Field
                        fullWidth
                        name={`props.${index}.value`}
                        type="text"
                        label="Valor"
                        as={DropdownSelector}
                        fetchFunction={getCatalogue}
                        fetchParams={{ catalogueName: "PRODUCT_FINISH" }}
                        keyField="id"
                      />
                    ) : (
                      <Field
                        fullWidth
                        name={`props.${index}.value`}
                        type="text"
                        label="Valor"
                        as={TextField}
                      />
                    )}
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton onClick={() => remove(index)} color="error">
                      <Delete />
                    </IconButton>
                  </Grid>
                </>
              ))}
              <Button
                fullWidth
                type="button"
                onClick={() => push({ key: "", value: "" })}
                variant="contained"
                sx={{ mt: 2, mx: 2 }}
              >
                <Icon>add</Icon>
              </Button>
            </Grid>
          )}
        </FieldArray>
      </Grid>
    </Grid>
  );
}
